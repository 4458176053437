

const SelectField = ({label, options, onChange}) => {

    return (
        <div className="input-field">
            <label>{label}</label>
            <br/>
            <select onChange={onChange}>                
                {Object.keys(options).map((option) => (<option key={option} value={option}>{options[option].value}</option>))}
            </select>
        </div>
    )
};

export default SelectField;