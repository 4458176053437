import useAuth from "../hooks/useAuth";
import { useEffect} from "react";
import {Navigate} from "react-router-dom";


const Logout = () => {
    const {setAuth} = useAuth();
    const {mounted, setMounted} = useEffect(false);
    
    useEffect(() => {
        setAuth();
        setMounted(true);
    }, [])

    return mounted ? <Navigate to='/login'/> : <div>Logout out</div>;
};

export default Logout;