import {Navigate, Outlet} from "react-router-dom";
import {useState} from "react";
import useAuth from "../hooks/useAuth";



export const ProtectedRoute = () => {
    const [authorized, setAuthorized] = useState(undefined);
    const {auth} = useAuth();

    if(authorized === undefined) {
        if(auth?.auth_token === undefined){
            setAuthorized(false);
        }
        else{
            setAuthorized(true);
        }
    }

    if(authorized === undefined) {
        return null;
    }

    return authorized ? <Outlet/> : <Navigate to="/login"/>
};