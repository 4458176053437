import React, { useState, useEffect } from "react";
import InputField from "../components/InputField";
import axios from 'axios';
import useAuth from "../hooks/useAuth";
import useNav from '../hooks/useNav';
import LoadingCircle from "../components/LoadingCircle";

const Login = () => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [loadingData, setLoadingData] = useState(false);

    const { auth, setAuth } = useAuth();
    const { setPath } = useNav();
    const [errorMsg, setErrorMsg] = useState(false);

    useEffect(() => {
    }, [])

    const handleLogin = async (e) => {
        setErrorMsg(false);
        e.preventDefault();
        const auth_token = btoa(username + ":" + password);
        try {
            setLoadingData(true);
            const response = await axios.get('https://rest.clicksend.com/v3/account',
                {
                    headers: { 'Content-Type': 'application/json', Authorization: 'Basic ' + auth_token }
                }
            );
            const { http_code } = await response.data;
            if (http_code === 200) {
                //TODO request to get tokens for all APIS
                //Verify auth token via clicksend. If accepted, return other tokens
                const res = await axios.post('https://s2.transactionmonitor.co.uk/send-sms-auth', { token: auth_token }, { headers: { 'Content-Type': 'application/json' } });
                const { status, response } = await res.data;
                if (status === 200) {
                    const twilio = response[0].twilio;
                    const infobip = response[0].infobip;
                    setAuth({ auth_token, twilio, infobip });
                    setPath('/');
                }
            };
        }
        catch (error) {
            const status = error.response.status;
            setLoadingData(false);
            console.log("Status ", error);
            if (status === 401) {
                setErrorMsg('Invalid username or password.');
            }
            else {
                setErrorMsg('Something went wrong. Please try again');
            }
        }
    };


    return (
        <div>
            {errorMsg &&
                <div style={{ border: "solid 1px #cc0000", padding: "7px", marginBottom: "5px"}}>
                    <p style={{color: "#cc0000", fontWeight: "bold", fontSize: "13px", margin: "0px"}}>{errorMsg}</p>
                </div>
            }
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px 25px" }}>
                <div style={{ gridColumn: "span 1" }}>
                    <InputField label="Username" onChange={(e) => { setUsername(e.target.value) }} editable={true} />
                </div>
                <div style={{ gridColumn: "span 1" }}>
                    <InputField label="Password" onChange={(e) => { setPassword(e.target.value) }} type="password" editable={true} />
                </div>
            </div>
            <button disabled={loadingData} className="button-blue" onClick={(e) => { handleLogin(e) }}>{loadingData ? <LoadingCircle isButton={true} /> : "Login"}</button>
        </div>
    );
};

export default Login;