import {axiosPrivate} from "../api";
import { useEffect } from "react";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    const {setAuth, auth} = useAuth();

    useEffect(() => {
        
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `Basic ${auth?.auth_token}`;
                }
                console.log("Config: ", config);
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                const status = error?.response?.status;
                if((status === 403 || status === 401) && !prevRequest?.sent){
                    //If it fails, navigate to login page. Set auth to false
                    prevRequest.sent = true;    
                    // return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.response.eject(responseIntercept);
            axiosPrivate.interceptors.request.eject(requestIntercept);
        }
    },[auth])

    return axiosPrivate;
}

export default useAxiosPrivate;