import React from "react";

const TextAreaField = ({label, onChange, value, type, editable}) => {
    
    return (
        <div className="input-field">
            <label>{label}</label>
            <br/>
            <textarea
                value={value}
                disabled={!editable}
                className="input-field"
                placeholder={label+'.. '}
                onChange={onChange}
                type={type}
                style={{height: "60px", width: "100%", resize: 'none'}}
            />
        </div>
    )
};

export default TextAreaField;