import axios from 'axios';
const BASE_URL = 'https://rest.clicksend.com/v3';
const INFOBIP_BASE_URL = 'https://pw583l.api.infobip.com';
const TWILIO_BASE_URL = 'https://api.twilio.com';

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
});



export const axiosInfoBip = axios.create({
    baseURL: INFOBIP_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const axiosTwilio = axios.create({
    baseURL: TWILIO_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});