import {axiosInfoBip} from "../api";
import { useEffect } from "react";
import useAuth from "./useAuth";

const useInfoBipAxiosPrivate = () => {
    const {setAuth, auth} = useAuth();

    useEffect(() => {
        
        const requestIntercept = axiosInfoBip.interceptors.request.use(
            config => {
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `App ${auth?.infobip}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosInfoBip.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                const status = error?.response?.status;
                if((status === 403 || status === 401) && !prevRequest?.sent){
                    //If it fails, navigate to login page. Set auth to false
                    prevRequest.sent = true;    
                    // return axiosInfoBip(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInfoBip.interceptors.response.eject(responseIntercept);
            axiosInfoBip.interceptors.request.eject(requestIntercept);
        }
    },[auth])

    return axiosInfoBip;
}

export default useInfoBipAxiosPrivate;