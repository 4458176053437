import InputField from "../components/InputField.js";
import SelectField from "../components/SelectField.js";
import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useInfoBipAxiosPrivate from "../hooks/useInfoBipAxiosPrivate.js";
import useTwilioAxiosPrivate from "../hooks/useTwilioAxiosPrivate.js";
import LoadingCircle from "../components/LoadingCircle";
import TextAreaField from "../components/TextAreaField.js";




const EMPTY_REGEX = /(.|\s)*\S(.|\s)*/;

const SendMessage = () => {

    const [messagingServiceOptions, setmessagingServiceOptions] = useState(
        {
            'clicksend': {from: "+1 2055256020", value: "ClickSend"},
            'infobip': {from: "kogoPAY", value: "InfoBip"},
            'twilio': {from: "kogoPAY", value: "Twilio"}
        }
    );


    const axiosTwilio = useTwilioAxiosPrivate();
    const axiosPrivate = useAxiosPrivate();
    const axiosInfoBip = useInfoBipAxiosPrivate();
    const {setAuth} = useAuth();
    const [messagingService, setMessagingService] = useState();
    const [fromMobile, setFromMobile] = useState();
    const [validFromMobile, setValidFromMobile] = useState(false);
    const [toMobile, setToMobile] = useState('');
    const [validToMobile, setValidToMobile] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [message, setMessage] = useState('');
    const [validMessage, setValidMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const [response, setResponse] = useState();

    useEffect(() => {
        const keys = Object.keys(messagingServiceOptions);
        keys.length > 0 && setMessagingService(keys[0]);
        setMounted(true);
    }, []);

    useEffect(() => {
        setValidFromMobile(EMPTY_REGEX.test(fromMobile))
    }, [fromMobile]);

    useEffect(() => {
        setValidToMobile(EMPTY_REGEX.test(toMobile));
    }, [toMobile]);


    useEffect(() => {
        if(!mounted){
            return;
        }
        setFromMobile(messagingServiceOptions[messagingService].from);
    }, [messagingService]);

    useEffect(() => {
        setValidMessage(EMPTY_REGEX.test(message));
    }, [message]);


    const handleSendViaInfoBip = () => {
        axiosInfoBip.post('/sms/2/text/advanced', {
            messages: {
                destinations: {
                    to: toMobile
                },
                from: "kogopay",
                text: message
            }
        })
        .then(
            (res) => {
                setResponse(JSON.stringify(res, null, 2));
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
            }
        )
    };

    //TODO Test Twilio Send SMS
    const handleSendViaTwilio = () => {
        const params = {Body: message, To: toMobile, MessagingServiceSid: 'MGaa5e86d07191da9bfdb5f86d7653423f'};
        const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
        setResponse('');
        axiosTwilio.post('/2010-04-01/Accounts/ACff77a8bd52c2d0c1677a898d2ae9b665/Messages.json', data)
            .then((res) => {
                setResponse(JSON.stringify(res, null, 2));
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
            }
        )
    }

    const handleSendViaClicksend = () => {
        setResponse('');
        axiosPrivate.post('/sms/send', { messages: [{ to: toMobile, from: fromMobile, body: message }] })
            .then(
                (res) => {
                    setResponse(JSON.stringify(res, null, 2));
                    setLoading(false);
                },
                (error) => {
                    console.error(error);
                    setLoading(false);
                }
            );
    }

    const handleSend = () => {
        setLoading(true);
        switch(messagingService){
            case 'clicksend':
                handleSendViaClicksend();
                break;
            case 'infobip':
                handleSendViaInfoBip();
                break;
            case 'twilio':
                handleSendViaTwilio();
                break;
            default:
                break;
        }
        setMessage('');
    };

    const isRequestValid = () => {
        return (!loading && validFromMobile && validToMobile && validMessage);
    };

    const handleLogout = () => {
        console.log("Logging out");
        setAuth();
    }

    return (
        <div>
            {mounted &&
                <div>
                    <a href="/logout"><button className="button-blue">Sign Out</button></a><br/><br/>
                    <h1>Send SMS via Infobip or Clicksend</h1><br/><br/>
                    <hr/>
                    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px 25px" }}>
                        <div style={{ gridColumn: "span 1" }}>
                            <SelectField label="Messaging Service" options={messagingServiceOptions} onChange={(e) => { setMessagingService(e.target.value) }} />
                        </div>
                        <div style={{ gridColumn: "span 1"}}>
                            <InputField label="Sender Phone Number" value={fromMobile} editable={false}/>
                        </div>
                        <div style={{ gridColumn: "span 1", gridRow: "2" }}>
                            <InputField label="Recipient Mobile Number" onChange={(e) => { setToMobile(e.target.value) }} value={toMobile} editable={true} />
                        </div>
                        <div style={{ gridColumn: "span 2", gridRow: "3"}}>
                            <TextAreaField label="Message Text" onChange={(e) => { setMessage(e.target.value) }} value={message} editable={true}/>
                        </div>
                    </div>

                    <button disabled={!isRequestValid()} className={"button-blue " + (isRequestValid() ? "" : "disabled")} onClick={() => { handleSend() }}>
                        {loading ? <LoadingCircle isButton={true} /> : "Send"}
                    </button>
                    <hr/><br/>

                    <h3>Response:</h3>
                    <div className="response-parent-div">
                        <pre>
                            <code>
                                {response}
                            </code>
                        </pre>
                    </div>
                </div>
            }
        </div>
    )
};

export default SendMessage;