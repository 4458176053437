import React from "react";

const InputField = ({label, onChange, value, type, editable}) => {
    
    return (
        <div className="input-field">
            <label>{label}</label>
            <br/>
            <input
                value={value}
                disabled={!editable}
                className="input-field"
                placeholder={label+'.. '}
                onChange={onChange}
                type={type}
            />
        </div>
    )
};

export default InputField;