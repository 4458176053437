import React from 'react';
import '../css/LoadingCircle.css';

const LoadingCircle = ({isButton}) => {
  return (
    <div className="loading-circle-container">
      <div className={isButton ? "loading-circle-button": 'loading-circle'}></div>
    </div>
  );
};

export default LoadingCircle;