import './App.css';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Login from './scenes/Login';
import Logout from './scenes/Logout';
import SendMessage from './scenes/SendMessage';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route index element={<SendMessage />} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
